


































































  import { Action, Component, Vue } from 'nuxt-property-decorator'
  import { Auth } from '@one/types'
  import { namespace } from 'vuex-class'

  const auth = namespace('auth')
  const layout = namespace('layout')
  const cms = namespace('cms')
  const cart = namespace('cart')
  const account = namespace('account')
  const stocks = namespace('stocks')

  @Component({
    middleware: ['isNotAuthenticated'],
    components: {
      OneLoginForm: () => import('~/components/organisms/auth/OneLoginForm.vue'),
      OnePasswordFormReset: () => import('~/components/organisms/auth/OnePasswordFormReset.vue'),
    },
  })
  export default class Login extends Vue {
    @auth.Action loginUser: any;
    @cms.State(state => state.configuration.registrationEnabled) isRegistrationEnabled: any;
    @layout.Mutation('TOGGLE_BREADCRUMBS') toggleBreadcrumbs: any;
    @layout.Mutation('SET_PRICE_GROSS') setPriceGross: any;
    @stocks.Mutation('SELECT_CENTRAL_WAREHOUSE_IF_NOT_SELECTED') selectDefaultWarehouseIfNotSelected: any;
    @cart.Getter getCurrentCartId: any;
    @account.Getter getClient: any
    @Action fetchRequiredData: any;
    @Action fetchHeavyData: any;
    @Action fetchAuthenticatedUserData: any;
    @Action clearStore: any;

    isPasswordReset : boolean = false
    mainComponentClass = 'one-login-page'
    passwordResetEmail: string = '';

    loginForm: Auth.Requests.Login = {
      email: '',
      password: '',
    }

    mounted() {
      this.$plugins.onMounted(this.mainComponentClass, this)
    }

    destroyed() {
      this.$plugins.onUnmounted(this.mainComponentClass)
    }

    created() {
      this.toggleBreadcrumbs(false)
    }

    toRegistrationPage() {
      this.$router.push(`${this.localePath('registration')}`)
    }

    loginToAccount(data: Auth.Requests.Login) {
      this.$wait.start('login')
      const credentials = {
        email: data.email,
        password: data.password,
      }
      this.$auth.login(credentials)
        .then(async () => {
          this.$channel.send({
            action: 'LOGIN',
            content: {},
          })
          await this.clearStore()
          this.$api.invalidateAllInstances()
          await this.fetchRequiredData()
          await this.fetchAuthenticatedUserData()
          this.selectDefaultWarehouseIfNotSelected()
          this.$api.webhooks.app.publishClientLogged({
            externalId: this.getClient.externalId,
          })
          if (await this.$auth.passwordChangeIsRequired()) {
            await this.$router.push(`${this.localePath('login')}/change`)
          } else {
            this.$router.push('/', () => {
              this.fetchHeavyData()
            })
          }
        })
        .catch((e) => {
          this.$logger.warn(e)
          this.$notify({
            group: 'alert',
            type: 'error',
            text: this.$t('login_page.incorrect_login') as string,
          })
        })
        .finally(() => {
          setTimeout(() => this.$wait.end('login'), 150)
        })
    }

    resetPassword(email: string) {
      this.$wait.start('password-reset')
      const credentials = {
        email,
      }
      this.$api.authserver.app.resetPassword(credentials)
        .then(() => {
          this.$notify({
            group: 'alert',
            type: 'success',
            text: this.$t('login_page.success_change_password') as string,
          })
        })
        .catch(() => {
          this.$notify({
            group: 'alert',
            type: 'error',
            text: this.$t('login_page.success_change_password') as string,
          })
        })
        .finally(() => setTimeout(() => this.$wait.end('password-reset'), 150))
      this.loginForm = {
        email: '',
        password: '',
      }
      this.isPasswordReset = !this.isPasswordReset
    }
  }
